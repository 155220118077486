<template>
  <div
    id="chartdiv"
    ref="chartdiv"
  />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU"

export default {
  props: [
    "agents",
    "levels",
    "predictions",
    "predictionChannels",
    "graphData",
    "graphDataWithPredictions",
    "checkedPredictionNames",
    "temperatures",
    "warnings",
    "showTemperatureText",
  ],
  data() {
    return {
      device_id: null,
      chart: null,
      data: [],
      alarmSeries: null,
      warnSeries: null,
      button: "",
      ValueAxis: null,
      DateAxis: undefined,
      seriesPredictMean: [],
      trendColor: ["#FAA700", "#6DD400", "#0091FF", "#6236FF", "#ff00c8", "#9B9B9B", "#44D7B6", "#0D7B80"],
    }
  },
  watch: {
    //listen action, if we get additional data, we concatenate to arrays in one
    // warnings: {
    //   immediate: false,
    //   handler(newValue, oldValue) {
    //     if (newValue.length > 0 && this.chart) {
    //       this.chart.data = [...this.data, ...newValue]
    //       this.chart.validate()
    //     }
    //     // this.data = [...newValue, ...this.data]
    //   },
    // },
    graphData(newValue) {
      this.chart.data = newValue
      this.chart.validate()
    },
    checkedPredictionNames: {
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // if(newValue && newValue.length > 0) {
          //   const lastValue = this.data[this.data.length - 1]
          //   const value = {
          //     mean1: lastValue['avg1'],
          //     mean2: lastValue['avg2'],
          //     mean3: lastValue['avg3'],
          //     mean4: lastValue['avg4'],
          //     mean5: lastValue['avg5'],
          //     mean6: lastValue['avg6'],
          //     mean7: lastValue['avg7'],
          //     mean8: lastValue['avg8'],
          //     time: lastValue['time']
          //   }
          //   this.chart.data = [...this.data, value, ...this.predictions]
          // } else {
          //   this.chart.data = this.data
          // }

          for (let name in this.seriesPredictMean) {
            this.chart.series.removeIndex(this.chart.series.indexOf(this.seriesPredictMean[name])).dispose()
          }

          this.seriesPredictMean = []
          if(Array.isArray(newValue)) {
            this.chart.data = newValue.length > 0 ? this.graphDataWithPredictions : this.graphData
            newValue.forEach((name, idx) => {
              this.drawPredictions(name, idx)
            })
          }
        }
      },
    },
    //listen checkbox actions
    levels() {
      this.levels.includes("Alarm") ? this.alarmSeries.show() : this.alarmSeries.hide()
      this.levels.includes("Warning") ? this.warnSeries.show() : this.warnSeries.hide()
    },
  },
  mounted() {
    //create an instance of amCharts
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

    //language
    chart.language.locale = am4lang_ru_RU

    let data = this.graphData
    // if (this.temperatures.data) data = [...data, ...this.temperatures.data]
    // if (this.warnings && this.warnings.length) data = [...data, ...this.warnings]
    // if (this.agents.data) {
    //   data = [...data, ...this.agents.data]
    // }

    chart.data = data
    this.data = data

    // Create axes date X
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.grid.template.location = 0
    dateAxis.renderer.minGridDistance = 80
    dateAxis.startLocation = 0
    dateAxis.endLocation = 0
    // dateAxis.skipEmptyPeriods = true;

    // dateAxis.baseInterval = { timeUnit: "minute", count: 1 };
    this.DateAxis = dateAxis

    let valueAxisTemperature
    if (this.showTemperatureText) {
      valueAxisTemperature = this.createValueAxis(chart, "C", "400")
    }
    const valueAxis = this.createValueAxis(chart, "мм/с")
    valueAxis.syncWithAxis = valueAxisTemperature

    const valueAxisSpeed = this.createValueAxis(chart, "об/мин", "600", true, 0)
    valueAxisSpeed.syncWithAxis = valueAxis

    // valueAxis.syncWithAxis = valueAxisTemperature
    // valueAxis.syncWithAxis = valueAxisSpeed
    // valueAxisTemperature.syncWithAxis = valueAxis
    // valueAxisTemperature.syncWithAxis = valueAxisSpeed
    // valueAxisSpeed.syncWithAxis = valueAxis
    // valueAxisSpeed.syncWithAxis = valueAxisTemperature

    this.ValueAxis = valueAxis

    this.agents.forEach((name, idx) => {
      if (name !== "SPEED")
        this.createSeries(chart, "avg" + (idx + 1), name, idx, valueAxis, this.trendColor[idx], false, false)
    })

    this.temperatures && this.temperatures.forEach((temp, idx) => {
      this.createSeries(chart, "tmp" + (idx + 1), temp, idx + 10, valueAxisTemperature, null, false, false)
    })

    // if (this.predictions?.length) this.drawPredictions(chart)

    //call the func of speed
    this.createSeries(chart, "speed", "Скорость", 0, valueAxisSpeed, "#B620E0")

    // speedSeries.yAxis.adapter.add("getTooltipText", function(text, target) {
    //   var cursorPosition = chart.cursor.yPosition;
    //   return text + " : " + series2.yAxis.getTooltipText(cursorPosition);
    // });
    // series2.yAxis.cursorTooltipEnabled = false;

    //call the func of Alarm
    this.alarmSeries = this.createSeries(chart, "alarm", "Тревога", 0, this.ValueAxis, "red")
    this.warnSeries = this.createSeries(chart, "warning", "Предупреждение", 0, this.ValueAxis, "red")
    //create legend of series
    chart.legend = new am4charts.Legend()
    chart.legend.scrollable = true
    chart.legend.maxHeight = 150
    chart.legend.marginBottom = 20
    // chart.legend.position = "top";
    let markerTemplate = chart.legend.markers.template
    markerTemplate.width = 15
    markerTemplate.height = 10

    //create event to cursor
    chart.cursor = new am4charts.XYCursor()
    // chart.cursor.maxTooltipDistance = -1
    chart.cursor.behavior = "zoomX"
    chart.cursor.xAxis = dateAxis

    //create vue global value of chart instance
    this.chart = chart
  },
  beforeDestroy() {
    //if chart are dispose, vue call this func
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    createSeries(chart, field, name, zIndex, valueAxis, color, hiddenInLegend = false, connected = true) {
      if (chart) {
        const series = chart.series.push(new am4charts.LineSeries())
        series.dataFields.valueY = field
        series.dataFields.dateX = "time"
        series.name = name
        series.id = `${field}-${zIndex}`
        // series.connect = false;
        series.hiddenInLegend = hiddenInLegend
        series.sequencedInterpolation = hiddenInLegend
        series.connect = connected
        series.autoGapCount = 20

        if (color) {
          series.tooltip.fillcolor = color
          series.stroke = color
        }
        if (["alarm", "warning"].includes(field)) {
          series.strokeDasharray = field === "warning" ? "8,4" : "0"
          series.hiddenInLegend = true
          series.cursorTooltipEnabled = false
        }
        // series.tooltipText = "{dateX}: [b]{valueY}[/]"
        series.tooltipText = "[{stroke.hex}]●[/]{name}: [bold]{valueY}[/]"
        // series.adapter.add("tooltipText", function(text) {
        //   text = "[bold]{dateX}[/]\n"
        //   for (const item of chart.series) {
        //     if ( !item.isHidden && !["alarm", "warning"].includes(item.dataFields.valueY)) {
        //       text += "[" + item.stroke.hex + "]●[/] " + item.name + ": [bold]{" + item.dataFields.valueY + "}[/]\n"
        //     }
        //   }
        //   return text
        // })
        series.tooltip.getFillFromObject = false
        series.tooltip.background.fill = am4core.color("#fff")
        series.tooltip.label.fill = am4core.color("#000")
        series.strokeWidth = 2
        series.zIndex = zIndex
        series.yAxis = valueAxis
        series.chart = chart
        series.tensionX = 1
        // series.bullets.push(new am4charts.CircleBullet());
        // series.cursorTooltipEnabled = false;

        return series
      }
    },

    createValueAxis(chart, text, fontWeight = "600", isOpposite = false, min) {
      if (chart) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.opposite = isOpposite
        valueAxis.showOnInit = true
        valueAxis.title.text = text
        valueAxis.title.rotation = 90
        valueAxis.title.valign = "top"
        valueAxis.title.fontWeight = fontWeight
        valueAxis.renderer.grid.template.strokeOpacity = 0.1
        valueAxis.min = !isNaN(min) ? min : undefined
        // if(chart.yAxes.indexOf(valueAxis) !== 0){
        //   valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        // }

        return valueAxis
      }
      return null
    },

    drawPredictions(predictionChannelName) {
      this.predictionChannels.forEach((pred, i) => {
        if (pred === predictionChannelName) {
          // this.createSeries(this.chart, `mean${i+1}`, `mean vib${i+1}`, 20, this.ValueAxis, 'yellow', true)
          this.seriesPredictMean[predictionChannelName] = this.chart.series.push(new am4charts.LineSeries())
          this.seriesPredictMean[predictionChannelName].dataFields.valueY = "mean" + (i + 1)
          this.seriesPredictMean[predictionChannelName].dataFields.dateX = "time"
          this.seriesPredictMean[predictionChannelName].name = `mean ${predictionChannelName}`
          this.seriesPredictMean[predictionChannelName].tooltipText = "{name}: [bold]{valueY}[/]"
          this.seriesPredictMean[predictionChannelName].strokeWidth = 3
          this.seriesPredictMean[predictionChannelName].stroke = "red"
          this.seriesPredictMean[predictionChannelName].zIndex = 0
          this.seriesPredictMean[predictionChannelName].hiddenInLegend = true
          this.seriesPredictMean[predictionChannelName].yAxis = this.ValueAxis
          this.seriesPredictMean[predictionChannelName].sequencedInterpolation = true

          // this.createSeries(this.chart, `upper${i+1}`, `MinMax vib${i+1}`, 20, this.ValueAxis, 'orange', true)
          // this.seriesPredictMinMax[predictionChannelName] = chart.series.push(new am4charts.LineSeries());
          //
          // this.seriesPredictMinMax[predictionChannelName].dataFields.openValueY = "lower" + (i + 1);
          // this.seriesPredictMinMax[predictionChannelName].dataFields.valueY = "upper" + (i + 1);
          // this.seriesPredictMinMax[predictionChannelName].dataFields.dateX = "time";
          // this.seriesPredictMinMax[predictionChannelName].name = "MinMax vib" + (i + 1);
          // this.seriesPredictMinMax[predictionChannelName].tooltipText = "lower: {openValueY.value} upper: {valueY.value}";
          // this.seriesPredictMinMax[predictionChannelName].strokeWidth = 0;
          // this.seriesPredictMinMax[predictionChannelName].stroke = "gray";
          // this.seriesPredictMinMax[predictionChannelName].zIndex = 0;
          // this.seriesPredictMinMax[predictionChannelName].hiddenInLegend = true;
          // this.seriesPredictMinMax[predictionChannelName].yAxis = this.ValueAxis;
          // this.seriesPredictMinMax[predictionChannelName].sequencedInterpolation = true;
          // this.seriesPredictMinMax[predictionChannelName].tooltip.background.fill = "gray";
          // this.seriesPredictMinMax[predictionChannelName].fill = "gray";
          // this.seriesPredictMinMax[predictionChannelName].fillOpacity = 0.2;
        }
      })

    },
  },
}
</script>
